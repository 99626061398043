import { mapState, mapActions } from 'vuex'
import CompUser from '@/models/CompUser'
import Comp from '@/models/Comp'

export default {
  data: () => ({
    loadingParticipate: false,
  }),
  computed: {
    ...mapState(['user']),
    subscription() {
      return this.comp && this.comp.comp_users.find(cu => cu.user_id == this.user.id)
    },
    subscribed() {
      return this.subscription && !this.subscription.deleted ? this.subscription : null
    },
    approved() {
      return this.subscribed && (this.subscription.approved || !this.comp.approve_participation)
    },
    participatable() {
      return !this.subscribed && !this.comp.expired
    },
    loggable() {
      return this.subscribed && !this.comp.expired
    },
    infoMessage() {
      if (this.subscribed) {
        if (this.approved) {
          if (this.comp.expired) {
            return 'competitions.participated'
          } else {
            return 'competitions.participating'
          }
        } else {
          return 'competitions.subscribed'
        }
      } else {
        return ''
      }
    },
    genderRequired() {
      return this.comp.split_gender && !['male', 'female'].includes(this.user.gender)
    },
  },
  methods: {
    ...mapActions('toast', ['info', 'success']),
    ...mapActions('dialog', ['confirm']),
    async participate() {
      if (this.genderRequired) return this.showRequireGenderDialog()

      this.loadingParticipate = true
      if (this.subscription && this.subscription.deleted) {
        await this.subscription.$apiUpdate({
          deleted: false,
          deleted_at: null,
        })
      } else {
        await CompUser.$apiCreate({
          user_id: this.user.id,
          group_id: this.comp.id,
        })
      }
      this.loadingParticipate = false

      await Comp.$apiFind(this.comp.id, { includes: 'poules' })

      await this.showPouleSelector()

      if (this.comp.approve_participation && !this.subscription.approved) {
        let msg = this.$t('competitions.approvingToastr') + ' ' + this.$t('competitions.approvingToastrMsg')
        if (this.comp.started) msg = msg + ' ' + this.$t('competitions.approvingMsg2')
        this.info(msg)
      } else {
        this.success(`${this.$t('generic.success')}. ${this.$t('competitions.approvedToastrMsg')}`)
      }
    },
    showRequireGenderDialog() {
      this.confirm({
        title: this.$t('competitions.genderRequiredMsg'),
        ok: this.$t('competitions.setGender'),
        onSave: () => this.$router.push({ name: 'profile' }),
      })
    },
    async showPouleSelector() {
      if (this.comp.poules.length <= 1) return
      const component = () => import('./tl-comp-poule-select')
      const props = {
        poules: this.comp.poules,
        compUser: this.subscription,
      }
      await this.$store.dispatch('dialog/open', { component, props })
    },
  },
}
