<template>
  <div class="tl-timeframe">
    <time v-if="isSingleDay" class="d-block text-center body-1 font-weight-bold">
      {{ start | formatDate('MMM Do') }}
    </time>
    <v-row no-gutters>
      <v-col class="tl-timeframe__start-time d-flex flex-column align-center">
        <div class="tl-timeframe__connecting-line"></div>
        <div class="tl-timeframe__dot"><v-icon>tl-access-time</v-icon></div>
        <div class="font-weight-bold">{{ $t('competitions.start') }}</div>
        <time v-if="!isSingleDay">{{ start | formatDate('MMM Do') }}</time>
        <time>{{ start | formatDate('k:mm') }}</time>
      </v-col>
      <v-col class="tl-timeframe__center-piece" flex>
        <div class="tl-timeframe__connecting-line"></div>
      </v-col>
      <v-col class="tl-timeframe__end-time d-flex flex-column align-center">
        <div class="tl-timeframe__connecting-line"></div>
        <div class="tl-timeframe__dot"><v-icon>tl-alarm</v-icon></div>
        <div class="font-weight-bold">{{ $t('competitions.deadline') }}</div>
        <time v-if="!isSingleDay">{{ end | formatDate('MMM Do') }}</time>
        <time>{{ end | formatDate('k:mm') }}</time>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    start: { type: Date, default: () => new Date() },
    end: { type: Date, default: () => new Date() },
  },
  computed: {
    isSingleDay() {
      return this.start && this.end && this.start.toDateString() === this.end.toDateString()
    },
  },
}
</script>

<style lang="sass">
.tl-timeframe
  margin: 16px 0

  &__connecting-line
    height: 2px
    background-color: var(--v-primary-base)
    position: absolute
    top: 12px
    left: 0
    right: 0
    transform: translateY(-50%)
    z-index: 0

  &__start-time &__connecting-line
    left: calc(50% + 15px)
  &__end-time &__connecting-line
    right: calc(50% + 15px)

  &__center-piece
    position: relative
    min-width: 50px

  &__start-time,
  &__end-time
    position: relative
    width: 100px

  &__dot
      margin-bottom: 8px
</style>
