<template>
  <v-card max-width="800" class="mx-auto">
    <v-alert v-if="awaitingApproval && !comp.expired" type="warning">
      <strong>{{ $t('competitions.unranked') }}: </strong> {{ $t('competitions.approvingMsg') }}
      <span v-if="comp.started"> {{ $t('competitions.approvingMsg2') }}</span>
    </v-alert>

    <v-alert v-if="genderRequired" type="warning">
      <strong>{{ $t('competitions.unranked') }}: </strong> {{ $t('competitions.genderRequiredMsg') }}
    </v-alert>

    <v-img :src="require('@/components/shared/tl-ranking/ranking-banner.png')" max-height="200px" contain />

    <v-card-text v-if="delayedScores" class="text-center">{{ $t('competitions.delayedScoresMsg') }}</v-card-text>
    <v-card-actions v-if="delayedScores" class="justify-center">
      <v-btn text @click="fetch">{{ $t('competitions.refresh') }}</v-btn>
    </v-card-actions>

    <tl-ranking
      :table="table"
      :users="rankedUsers"
      :user="rankedUser"
      :genders="genders"
      :gender.sync="gender"
      :poules="poules"
      :poule.sync="poule"
      :loading="loading"
      @update:user="showUser($event)"
    />
  </v-card>
</template>

<script>
import CompUser from '@/models/CompUser'
import tlRanking from '@/components/shared/tl-ranking/tl-ranking'
import scoreSystems from '../score-systems/score-systems'

export default {
  components: {
    tlRanking,
  },
  props: {
    comp: { type: Object, default: () => ({}) },
    user: { type: Object, default: () => ({}) },
    table: { type: Boolean, default: false },
  },
  data: () => ({
    loading: false,
    compUsers: [],
    gender: null,
    poule: null,
  }),
  computed: {
    genders() {
      return this.comp.split_gender ? ['male', 'female'] : []
    },
    poules() {
      return this.comp.poules || []
    },
    delayedScores() {
      let scoreSystem = scoreSystems.find(sc => sc.name == this.comp.score_system)
      return scoreSystem && scoreSystem.delayedScores
    },
    compUser() {
      return this.comp.comp_users.find(cu => cu.user_id == this.user.id && !cu.deleted)
    },
    awaitingApproval() {
      return this.compUser && this.comp.approve_participation && !this.compUser.approved
    },
    genderRequired() {
      return this.compUser && this.genders.length && !this.genders.includes(this.user.gender)
    },
    rankedUsers() {
      return this.compUsers
        .map(compUser => ({
          id: compUser.user_id,
          uid: compUser.user.uid,
          groupId: compUser.id,
          gender: compUser.user.gender,
          poule: compUser.poule,
          rank: compUser.rank,
          avatar: compUser.user.avatar,
          full_name: compUser.user.fullName,
          email: compUser.user.email,
          score: compUser.score,
        }))
        .sort((a, b) => {
          return b.score - a.score || a.rank - b.rank || a.full_name.localeCompare(b.full_name)
        })
    },
    rankedUser() {
      if (!this.user) return null
      return this.rankedUsers.find(u => u.id == this.user.id)
    },
    filters() {
      let filters = {
        group_id: this.comp.id,
        deleted: false,
      }
      if (this.comp.approve_participation) filters.approved = true
      if (this.comp.split_gender) filters.user = { gender: this.gender }
      if (this.poule) filters.poule_id = this.poule.id
      return filters
    },
  },
  watch: {
    filters() {
      this.fetch()
    },
    'compUser.poule_id'() {
      this.fetch()
    },
  },
  mounted() {
    this.$nextTick(() => {
      // If the watch didn't fire a fetch (if no split_gender and no poules), trigger it now:
      if (!this.loading) this.fetch()
    })
  },
  methods: {
    fetch() {
      this.loading = true
      CompUser.$apiAll({
        params: {
          json_params: {
            filters: this.filters,
            includes: 'user',
          },
          serializeall: this.table,
        },
      })
        .then(compUsers => {
          this.compUsers = compUsers
        })
        .finally(() => (this.loading = false))
    },
    showUser(rankedUser) {
      let tooLongAgo = this.moment(this.comp.date_loggable_end).isBefore(this.moment().subtract(3, 'days'))
      if (tooLongAgo) return // Prevent showing ranking results where there are no climbs anymore
      if (!rankedUser) return
      this.$router.pushSmart({ name: 'comp.climbs', query: { uid: rankedUser.uid } })
    },
  },
}
</script>
