<template>
  <v-card light :dark="comp.expired" :loading="loadingParticipate" class="elevation-2">
    <v-alert v-if="!comp.live" type="warning" dense icon="tl-visibility-off">Not live</v-alert>
    <v-alert v-if="!isAdmin && infoMessage" type="info" dense icon="tl-check">
      {{ $t(infoMessage) }}
    </v-alert>
    <!-- <v-img :src="require('./rankings-banner.png')" height="200px" /> -->
    <v-card-title class="headline">{{ comp.name }}</v-card-title>
    <v-card-text>
      <div class="caption d-flex align-center">
        <v-icon small class="pr-2">tl-event</v-icon>
        <span>{{ comp.date_loggable_start | formatDate }}</span>
        <span v-if="!comp.isSingleDay">
          <v-icon small class="px-2">tl-remove</v-icon>
          {{ comp.date_loggable_end | formatDate }}
        </span>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn v-if="approvable" text link :to="getLink('admin.comp.participants')">
        {{ $vuetify.breakpoint.smAndUp ? 'Approve' : '' }} participants
      </v-btn>
      <v-btn v-if="isAdmin" text link :to="getLink('admin.comp.results')">Results</v-btn>
      <v-spacer />
      <v-btn
        v-if="participatable && !isAdmin"
        text
        color="primary"
        :loading="loadingParticipate"
        :disabled="loadingParticipate"
        @click="participate"
      >
        {{ $t('competitions.participate') }}
      </v-btn>
      <v-btn v-if="subscribed && !isAdmin" :disabled="!loggable" text color="primary" link :to="getLink('comp.climbs')">
        {{ $t(`dashboard.ranking.${this.comp.climbs_type == 'boulder' ? 'logBouldersBtn' : 'logRoutesBtn'}`) }}
      </v-btn>
      <v-btn v-if="isAdmin" text link :to="getLink('admin.comp.edit')" icon><v-icon>tl-edit</v-icon></v-btn>
      <v-btn v-else text link :to="getLink('comp.details')">{{ $t('generic.details') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import Comp from '@/models/Comp'
import tlCompMixin from './tl-comp.mixin'

export default {
  mixins: [tlCompMixin],
  props: {
    comp: { type: Comp, default: {} },
  },
  computed: {
    ...mapState(['isAdmin']),
    approvable() {
      return this.isAdmin && this.comp.approve_participation && !this.comp.expired
    },
  },
  methods: {
    getLink(name) {
      return { name, params: { compId: this.comp.id } }
    },
  },
}
</script>
