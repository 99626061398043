<template>
  <div>
    <v-card-actions v-if="genders.length > 1" class="justify-center">
      <v-btn
        v-for="(g, index) in genders"
        :key="index"
        :color="g == gender ? 'primary' : ''"
        large
        icon
        @click="$emit('update:gender', g)"
      >
        <v-icon>tl-{{ g }}</v-icon>
      </v-btn>
    </v-card-actions>

    <v-tabs v-if="poules.length" v-model="pouleIndex" centered center-active show-arrows>
      <v-tab v-for="p in poules" :key="p.id" v-text="p.name" />
    </v-tabs>
    <v-divider />

    <v-card-text v-if="poule" class="text-center">{{ poule && poule.description }}</v-card-text>

    <div v-if="loading" class="d-flex justify-center">
      <v-progress-circular indeterminate color="primary" :size="50" class="my-10" />
    </div>

    <template v-else>
      <v-data-table
        v-if="table"
        dense
        :headers="headers"
        :items="users"
        item-key="id"
        :items-per-page="-1"
        :mobile-breakpoint="0"
        hide-default-footer
        disable-sort
        class="tl-rankings-table"
      />

      <template v-else>
        <v-list flat>
          <v-list-item v-if="!users.length">
            <v-list-item-content class="text-center">
              <v-list-item-title v-t="'generic.noData'" />
            </v-list-item-content>
          </v-list-item>
          <v-list-item-group v-model="activeUserId" color="primary">
            <template v-for="(u, index) in usersPaginated">
              <v-list-item :value="u.id" :key="u.id">
                <v-list-item-action class="mr-0 justify-center">
                  <slot name="rank" :user="u" :index="index">{{ u.rank }}</slot>
                </v-list-item-action>

                <tl-list-item-avatar :avatar="u.avatar" class="mx-4" />

                <v-list-item-content>
                  <slot :user="u">
                    <v-list-item-title v-text="u.full_name" />
                  </slot>
                </v-list-item-content>

                <v-list-item-action>
                  <slot name="score" :user="u">{{ u.score }}</slot>
                </v-list-item-action>
              </v-list-item>
              <v-divider :key="-index" />
            </template>
          </v-list-item-group>
        </v-list>

        <v-card-actions class="text-center">
          <tl-pagination-local
            :list="users"
            :list-item-init="user"
            :items-per-page="itemsPerPage"
            @list-paginated="usersPaginated = $event"
          />
        </v-card-actions>
      </template>
    </template>
  </div>
</template>

<script>
import tlListItemAvatar from '@/components/shared/tl-list-item-avatar'
import tlPaginationLocal from '@/components/shared/tl-pagination-local'

export default {
  components: {
    tlListItemAvatar,
    tlPaginationLocal,
  },
  props: {
    loading: { type: Boolean, default: false },
    users: { type: Array, default: () => [] },
    user: { type: Object, default: () => null },
    genders: { type: Array, default: () => [] },
    gender: { type: String, default: () => null },
    poules: { type: Array, default: () => [] },
    poule: { type: Object, default: () => null },
    page: { type: Number, default: 0 },
    table: { type: Boolean, default: false },
    itemsPerPage: { type: Number, default: 20 },
    serverItemsLength: { type: Number, default: null },
  },
  data: () => ({
    usersPaginated: [],
    headers: [
      { text: 'Rank', value: 'rank' },
      { text: 'Score', value: 'score' },
      { text: 'Name', value: 'full_name' },
      { text: 'Email', value: 'email' },
    ],
  }),
  computed: {
    pouleIndex: {
      get() {
        if (this.poule == null) return 0
        return this.poules.map(p => p.id).indexOf(this.poule.id)
      },
      set(index) {
        this.$emit('update:poule', this.poules[index])
      },
    },
    activeUserId: {
      get() {
        if (!this.user) return
        return this.user.id
      },
      set(userId) {
        let user = this.usersPaginated.find(u => u.id == userId)
        this.$emit('update:user', user)
      },
    },
  },
  created() {
    if (this.genders.length) this.assureGender()
    if (this.poules.length) this.assurePoule()
  },
  methods: {
    assureGender() {
      if (!this.gender) {
        const validUserGender = this.user && this.user.gender && this.genders.includes(this.user.gender)
        const initialGender = validUserGender ? this.user.gender : this.genders[0]
        this.$emit('update:gender', initialGender)
      }
    },
    assurePoule() {
      if (this.poule == null) {
        const validUserPoule = this.user && this.user.poule && this.poules.includes(this.user.poule)
        const initialPoule = validUserPoule ? this.user.poule : this.poules[0]
        this.$emit('update:poule', initialPoule)
      }
    },
  },
}
</script>

<style lang="sass">
.tl-rankings-table
  user-select: text !important
</style>
