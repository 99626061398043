<template>
  <v-container>
    <v-progress-linear v-if="loadingComp" indeterminate />

    <v-card v-if="comp" max-width="800" class="mx-auto mb-4">
      <v-alert v-if="!comp.started" type="warning" dense icon="tl-alarm">
        {{ $t('competitions.notStarted') }}
      </v-alert>
      <v-alert v-if="comp.expired" type="info" dense icon="tl-alarm">
        {{ $t('competitions.expiredComp') }}
      </v-alert>
      <v-card-title>{{ comp.name }}</v-card-title>
      <v-card-text>
        <p>{{ comp.description }}</p>
        <tl-timeframe :start="dateLoggableStart" :end="dateLoggableEnd" />
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="participatable"
          text
          color="primary"
          :loading="loadingParticipate"
          :disabled="loadingParticipate"
          @click="participate"
        >
          {{ $t('competitions.participate') }}
        </v-btn>
        <v-btn v-if="subscribed" :disabled="!loggable" text color="primary" link :to="{ name: 'comp.climbs' }">
          {{ $t(`dashboard.ranking.${this.comp.climbs_type == 'boulder' ? 'logBouldersBtn' : 'logRoutesBtn'}`) }}
        </v-btn>
        <div class="flex-grow-1"></div>
        <v-btn text @click="showDetails = !showDetails">
          {{ $t('generic.readMore') }}
          <v-icon right>{{ showDetails ? 'tl-chevron-up' : 'tl-chevron-down' }}</v-icon>
        </v-btn>
      </v-card-actions>

      <v-expand-transition>
        <div v-show="showDetails">
          <v-card-text>
            <div class="title">{{ $t('competitions.info.scoreSystem') }}</div>
            <component :is="currentSystem.component" :params="comp.score_system_params" :comp="comp" />

            <div v-if="comp.rules">
              <div class="title">{{ $t('competitions.info.rules') }}</div>
              <p>{{ comp.rules }}</p>
            </div>

            <div v-if="comp.prizes">
              <div class="title">{{ $t('competitions.info.pricesHeader') }}</div>
              <p>{{ comp.prizes }}</p>
            </div>
          </v-card-text>
        </div>
      </v-expand-transition>
    </v-card>

    <tl-comp-ranking v-if="comp" :comp="comp" :user="user" />
  </v-container>
</template>

<script>
import Comp from '@/models/Comp'
import toolbarMixin from '@/components/layout/toolbar/toolbar.mixin'
import tlCompMixin from '../tl-comp.mixin'
import tlCompRanking from './tl-comp-ranking'
import tlTimeframe from './tl-timeframe'
import scoreSystems from '../score-systems/score-systems'
import { parseDateSafe } from '@/services/parsers'

export default {
  mixins: [toolbarMixin, tlCompMixin],
  components: {
    tlTimeframe,
    tlCompRanking,
  },
  props: {
    comp: { type: Comp, default: () => {} },
    loadingComp: { type: Boolean, default: false },
  },
  data: () => ({
    unsubscribing: false,
    showDetails: false,
  }),
  computed: {
    tlToolbarTitle() {
      return this.comp && this.comp.name
    },
    tlToolbarBtnLeft() {
      return { icon: 'tl-arrow-back', action: this.$router.toBackRoute }
    },
    tlToolbarButtons() {
      let btns = []
      if (this.subscribed && !this.comp.expired) {
        btns.push({
          text: this.$t('competitions.switchPoule'),
          action: this.showPouleSelector,
        })
        btns.push({
          text: this.$t('competitions.unsubscribe'),
          action: this.unsubscribe,
        })
      }
      return btns
    },
    currentSystem() {
      return scoreSystems.find(system => system.name == this.comp.score_system)
    },
    dateLoggableStart() {
      return parseDateSafe(this.comp.date_loggable_start)
    },
    dateLoggableEnd() {
      return parseDateSafe(this.comp.date_loggable_end)
    },
  },
  methods: {
    async unsubscribe() {
      let confirmed = await this.$store.dispatch('dialog/confirm', {
        title: this.$t('competitions.unsubscribeTitle', { competitionName: this.comp.name }),
        text: this.$t('competitions.unsubscribeMsg'),
        ok: this.$t('competitions.unsubscribe'),
      })
      if (!confirmed) return

      this.unsubscribing = true
      this.subscription
        .$apiUpdate({
          deleted: true,
          deleted_at: new Date(),
        })
        .then(() => {
          this.$store.dispatch(
            'toast/success',
            this.$t('competitions.unsubscribedMsg', { competitionName: this.comp.name })
          )
          this.$router.pushSmart({ name: 'comps', params: { id: null } })
        })
        .finally(() => (this.unsubscribing = false))
    },
  },
}
</script>
