<template>
  <router-view :comp="comp" :loading-comp="loadingComp" />
</template>

<script>
import { mapState } from 'vuex'
import Comp from '@/models/Comp'
import CompUser from '@/models/CompUser'

export default {
  data: () => ({
    loadingComp: true,
    comp: null,
  }),
  computed: {
    ...mapState(['user']),
  },
  created() {
    // Load both the Competition and the compUsers of the current user. Wait for both to complete befor setting this.comp:
    let compId = this.$route.params.compId
    let compDfd = Comp.$apiFind(compId, {
      includes: ['poules', 'climb_groups', 'gym_groups'],
    })
    let compUserDfd = CompUser.$apiAll({
      params: {
        json_params: {
          filters: {
            user_id: this.user.id,
            group_id: compId,
          },
        },
      },
    })
    Promise.all([compDfd, compUserDfd])
      .then(results => {
        this.comp = results[0]
        this.$store.commit('setClimbType', this.comp.climbs_type)
      })
      .finally(() => (this.loadingComp = false))
  },
}
</script>
