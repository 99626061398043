<template>
  <div>
    <v-container>
      <v-alert v-if="!loading && !comps.length" type="info" border="left">
        {{ $t('competitions.noCompetitionsMsg', { gymName: gym.name }) }}
      </v-alert>

      <v-timeline :dark="isAdmin" v-if="!loading && comps.length" :dense="$vuetify.breakpoint.smAndDown">
        <v-timeline-item v-for="comp in comps" :key="comp.id" :dark="false" icon="tl-trophy" large>
          <tl-comps-comp :comp="comp" />
          <span slot="opposite" :dark="isAdmin">{{ comp.date_loggable_start | formatDate }}</span>
        </v-timeline-item>
      </v-timeline>

      <v-progress-linear v-if="loading" indeterminate />
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import toolbarMixin from '@/components/layout/toolbar/toolbar.mixin'
import CompGym from '@/models/CompGym'
import CompUser from '@/models/CompUser'
import tlCompsComp from './tl-comps-comp'
import scoreSystems from './score-systems/score-systems'
import { parseDateSafe } from '@/services/parsers'

export default {
  mixins: [toolbarMixin],
  components: {
    tlCompsComp,
  },
  head() {
    let description = this.$t('competitions.description', { gymName: this.gym.name })
    return {
      title: this.$t('competitions.title', { gymName: this.gym.name }),
      meta: [{ vmid: 'description', name: 'description', content: description }],
    }
  },
  data: () => ({
    loading: false,
    addingComp: false,
  }),
  created() {
    this.fetchComps()
  },
  computed: {
    ...mapState(['gym', 'user', 'isAdmin']),
    tlToolbarTitle() {
      return this.isAdmin ? 'Edit Competitions' : this.$t('sidenav.competitions')
    },
    tlToolbarButtons() {
      if (!this.isAdmin) return []
      return [
        {
          icon: 'tl-add',
          text: 'Create competition',
          loading: this.addingComp,
          action: this.addComp,
        },
      ]
    },
    comps() {
      return this.gym.comp_gyms
        .filter(compGym => !compGym.deleted && (this.isAdmin || compGym.comp.live))
        .map(compGym => compGym.comp)
        .sort((a, b) => parseDateSafe(b.date_loggable_start) - parseDateSafe(a.date_loggable_start))
    },
  },
  methods: {
    async fetchComps() {
      this.loading = true

      const params = {
        gym_id: this.gym.id,
        json_params: {
          filters: {
            deleted: false,
            added: true,
            group: {
              not: { score_system: 'none' },
            },
          },
          includes: 'group',
        },
      }
      if (!this.isAdmin) params.json_params.filters.group.live = true

      await CompGym.$apiAll({ params })
      await CompUser.$apiAll({ params: { json_params: { filters: { user_id: this.user.id } } } })
      this.loading = false
    },
    async addComp() {
      this.addingComp = true
      let compGym = await CompGym.$apiCreate({
        gym_id: this.gym.id,
        added: true,
        group: {
          live: false,
          climbs_type: this.gym.has_routes ? 'route' : 'boulder',
          date_loggable_start: this.moment().add(1, 'days').toDate(), // eslint-disable-line
          date_loggable_end: this.moment().add(2, 'days').toDate(), // eslint-disable-line
          score_system: scoreSystems[0].name,
          score_system_params: scoreSystems[0].paramDefaults,
        },
      })
      this.addingComp = false
      this.$router.push({ name: 'admin.comp.edit', params: { compId: compGym.comp.id } })
    },
  },
}
</script>
