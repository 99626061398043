export default [
  {
    name: 'points',
    label: 'Points',
    live: true,
    component: () => import('./points.vue'),
    paramDefaults: {
      points: 10,
      points_flash: 2,
      points_onsight: 3,
    },
  },
  {
    name: 'points_by_grade',
    label: 'Points by grade',
    live: true,
    component: () => import('./points-by-grade.vue'),
    paramDefaults: {
      points_flash: 16,
      points_onsight: 33,
      n_best_efforts: 10000,
    },
  },
  {
    name: 'thousand_divide_by',
    label: '1000/tops',
    live: true,
    component: () => import('./thousand-divide-by.vue'),
    paramDefaults: {
      bonus_percent_fl: 0,
      bonus_percent_os: 0,
    },
    delayedScores: true,
  },
  //   name: 'top_zone_tries_time'
  //   label: 'Official'
  //   live: false
  //   description: 'Ranking based on the number of tops, then the number of zones, then the number of tries and finally on time.'
  //   params: []
  // ,
  //   name: 'nr_of_holds'
  //   label: 'Nr of holds'
  //   live: false
  //   description: 'Ranking based on the number of tops, then the number of holds, optionally with a \'minus\' for only touching a hold.'
  //   params: []
]
